$primary: #870724;
$body-bg: #252525;
$font-size-base: 0.9375rem;

@import "~bootswatch/dist/slate/variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/slate/bootswatch";

@font-face {
    font-family: '28_days_laterregular';
    src: url(./assets/fonts/28dayslater/28_days_later-webfont.woff2) format('woff2'), url(./assets/fonts/28dayslater/28_days_later-webfont.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}

::-webkit-scrollbar-thumb {
    background: $primary;
    border: 0px none $primary;
    border-radius: 0;
}

::-webkit-scrollbar-thumb:hover {
    background: $primary;
}

::-webkit-scrollbar-thumb:active {
    background: $secondary;
}

::-webkit-scrollbar-track {
    background: $gray-500;
    border: 0px none $primary;
    border-radius: 0;
}

::-webkit-scrollbar-track:hover {
    background: #666666;
}

::-webkit-scrollbar-track:active {
    background: #333333;
}

::-webkit-scrollbar-corner {
    background: transparent;
}

a {
    cursor: pointer;
}

.fullwidth-fluid-container {
    padding: 0;
}

.page {
    padding-top: 3.5rem;
    margin-top: 1rem;
}

.page-header {
    position: relative;
    overflow: hidden;
    padding: 0.25rem 0;
    margin-bottom: 20px;

    h2 {
        margin: 0;
        color: white;
    }

    &:after {
        content: "";
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: -1;
        position: absolute;
        filter: blur(3px);
        transform: scale(1.1);
    }

    &.bg1:after {
        background: url(./assets/img/dayz2.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    &.bg2:after {
        background: url(./assets/img/dayz3.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    &.bg3:after {
        background: url(./assets/img/dayz4.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    &.bg4:after {
        background: url(./assets/img/dayz5.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    &.bg5:after {
        background: url(./assets/img/dayz6.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
}

.btn.sharp {
    border-radius: 0;
}

.btn.btn-block-50 {
    width: 50%;
    margin: 0 auto !important;
}

.form-control {
    border-radius: 0;
}

.server-desc {

    h1,
    h2 {
        font-size: 1.640625rem;
    }
}

.frontpage-inner-bg {
    position: relative;
    overflow: hidden;
    margin-top: 1rem;
    padding: 1rem 0;

    .frontpage-inner {
        text-align: center;

        h1 {
            font-family: '28_days_laterregular';
            font-size: 4rem;
            margin-bottom: 0;
            color: $primary;
            text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
        }

        .lead {
            margin-top: -15px;
        }
    }

    .launcher-screenshot {
        max-height: 450px;
        padding: 0 20px;
    }

    #download-button {
        margin: 10px 0;
    }

    button {
        margin: 0 5px;
    }

    &:after {
        content: "";
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-image: url(./assets/img/dayz5.png);
        opacity: 0.25;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: -1;
        filter: blur(3px);
        transform: scale(1.1);
    }
}

#sponsor {
    margin: 15px auto;

    a {
        display: inline-block;
    }
}

.footer {
    margin: 2rem 3rem;
    font-size: 80%;
    text-align: center;
	padding-top: 1rem;
	border-top: 1px solid #333;
}

.navbar {
    padding: 0 1rem;
}

.sponsor-amount {
    h4 {
        line-height: 40px;
        margin: 0;
        text-align: center;
    }
    p {
        line-height: 14px;
        font-size: 80%;
        text-align: center;
    }
}

.continent-checkboxes .form-check {
	padding-left: 2.75rem;
}

.lds-dual-ring {
	display: block;
	margin: 0 auto;
	width: 74px;
	height: 74px;
}
.lds-dual-ring:after {
	content: " ";
	display: block;
	width: 56px;
	height: 56px;
	margin: 9px;
	border-radius: 50%;
	border: 5px solid $primary;
	border-color: $primary transparent $primary transparent;
	animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

tr.inactive {
	td {
		color: #666;
	}
}

.form-bottom a {
	padding-right: 10px;
}